import { DeleteEnrollment } from "../../domain/usages/delete-enrollment";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpDeleteClient } from "../protocols/http/http-delete-client";

export class RemoteDeleteEnrollment implements DeleteEnrollment {
  constructor(
    private readonly url: string,
    private readonly httpDeleteClient: HttpDeleteClient
  ) {}
  async delete(id: number, params: DeleteEnrollment.Params): Promise<any> {
    console.log(id, params);
    const httpResponse = await this.httpDeleteClient.delete({
      url: this.url.replace(":id", id.toString()),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
