import {
  Typography,
  Grid,
  Box,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useProgramDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import { Constant } from "../../../../../constants/constant";
import {
  getDomain,
  getFieldValidation,
  toTitleCase,
} from "../../../../../domain/Utils";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddNewTacTestData from "./AddNewTacTestData";
import DomainName from "../../../../../domain/domain";
import moment from "moment";
import { FieldNames } from "../../../../../constants/enums/fieldNames";
import { Country } from "../../../../../domain/models/countires";

type ProgramItems = {
  program: string;
  product: string;
  couponcode: string;
  primarycontact: string;
  secondarycontact: string;
  doctor_name: string;
  hospital: string;
  treatmentcycle: string;
  dosage: string;
  diagnosis: string;
  comments: string;
  no_of_therapy_months: number;
  no_of_free_months: number;
  last_infusion_date: Date | string;
  next_infusion_date: Date | string;
  prescriptiondate: Date | string;
  treatmentdate: Date | string;
  transplantdate: Date | string;
  freecycledate: Date | string;
  total_cycles_completed: number;
  total_cycles_remaining: number;
  pack_scheme: string;
  iop: string;
  numberOfYearsOfGlucoma: number;
  currentDrugs: string;
  baselineIOP: string;
  country: Country | undefined;
  comorbidDiseaseconditions: string;
};

type TacTestData = { type: string; value: number };

const StaticProgram: React.FC = () => {
  const { handleEdit, handleUpdateTacTestData } = useProgramDetailStore();
  const { selectedCard, isBlurred, countriesList } = useEnrollmentMainStore();
  const { programOptions } = useProgramDetailStore();
  const [addTacTestData, setAddTacTestData] = useState<boolean>(false);
  const theme = useTheme();

  const [editedTacData, setEditedTacData] = useState<TacTestData>();
  const { control, setValue } = useForm<ProgramItems>({
    mode: "onChange",
  });

  const handleEditTacIndex = (type: string, value: number) => {
    setEditedTacData({
      type: type,
      value: value ? value : 0,
    });
  };

  const updateTacData = () => {
    let payload = {
      meta_data: {
        tac_test: [editedTacData],
      },
    };
    handleUpdateTacTestData(payload);
  };

  useEffect(() => {
    setValue(
      "doctor_name",
      selectedCard.doctor_full_name ? selectedCard?.doctor_full_name : " "
    );
    setValue("hospital", selectedCard.hospital ? selectedCard?.hospital : "");
    setValue(
      "prescriptiondate",
      selectedCard.prescription_date
        ? moment(selectedCard.prescription_date.toString()).format("ll")
        : " "
    );
    setValue("pack_scheme", selectedCard.pack_scheme);
    setValue(
      "transplantdate",
      selectedCard.transplant_date
        ? moment(selectedCard.transplant_date.toString()).format("ll")
        : " "
    );
    setValue(
      "treatmentdate",
      selectedCard.treatment_start_date
        ? moment(selectedCard.treatment_start_date.toString()).format("ll")
        : " "
    );
    setValue(
      "freecycledate",
      selectedCard.next_free_cycle_date
        ? moment(selectedCard.next_free_cycle_date.toString()).format("ll")
        : " "
    );
    setValue("treatmentcycle", selectedCard?.treatment_cycle);
    setValue("couponcode", selectedCard.coupon_code);
    setValue("diagnosis", selectedCard.diagnosis);
    setValue("dosage", selectedCard?.dosage);
    setValue("program", selectedCard.program.name);
    setValue("product", selectedCard.product.name);
    setValue("primarycontact", selectedCard.mobile);
    setValue("secondarycontact", selectedCard.alt_mobile);
    setValue("no_of_therapy_months", selectedCard.no_of_therapy_months);
    setValue("no_of_free_months", selectedCard.no_of_free_months);
    setValue("total_cycles_completed", selectedCard.total_cycles_completed);
    setValue("total_cycles_remaining", selectedCard.total_cycles_remaining);
    setValue(
      "last_infusion_date",
      selectedCard.last_infusion_date
        ? moment(selectedCard.last_infusion_date.toString()).format("ll")
        : " "
    );
    setValue(
      "next_infusion_date",
      selectedCard.next_infusion_date
        ? moment(selectedCard.next_infusion_date.toString()).format("ll")
        : " "
    );
    setValue("iop", selectedCard?.iop);
    setValue("baselineIOP", selectedCard?.baseline_iop);
    setValue("comorbidDiseaseconditions", selectedCard?.comorbid_conditions);
    setValue("numberOfYearsOfGlucoma", selectedCard?.disease_duration_years);

    setValue("comments", selectedCard?.comments);
    setValue(
      "country",
      countriesList.find((country) => country.id === selectedCard?.country_id)
    );
  }, [selectedCard.id]);

  const handleAddTacData = (value: boolean) => {
    setAddTacTestData(value);
  };

  const getTacTestData = () => {
    if (
      selectedCard.meta_data &&
      JSON.parse(selectedCard.meta_data).tac_test &&
      JSON.parse(selectedCard.meta_data).tac_test.length > 0
    ) {
      return JSON.parse(selectedCard.meta_data).tac_test;
    }
    return [];
  };

  if (programOptions.length > 0 && !programOptions[0].config_data == null) {
    return <div>Loading...</div>; // Or some fallback UI
  }

  return (
    <Box
      overflow="scroll"
      height="65vh"
      paddingBottom={10}
      sx={{ ...theme.scrollbar }}
    >
      <Stack
        justifyContent="space-between"
        direction={"row"}
        position={"fixed"}
        width={"42%"}
        bgcolor={"white"}
        zIndex={10}
        paddingBottom={1}
      >
        <Typography
          sx={{
            fontFamily: "Segoe UI",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            color: "#212121",
          }}
        >
          Program Details
        </Typography>
        {!isBlurred && (
          <Box>
            {getDomain() != DomainName.DOSTPLUSX && (
              <Button
                variant="text"
                onClick={() => handleEdit(1)}
                sx={{
                  color: "#3F51B7",
                  fontSize: "14px",
                  fontFamily: "Segoe UI",
                  fontStyle: " normal",
                  fontWeight: 500,
                  alignSelf: "flex-end",
                }}
              >
                SEND TO CFA
              </Button>
            )}
            <Button
              variant="text"
              onClick={() => handleEdit(2)}
              sx={{
                color: "#3F51B7",
                fontSize: "14px",
                fontFamily: "Segoe UI",
                fontStyle: " normal",
                fontWeight: 500,
                alignSelf: "flex-end",
              }}
            >
              EDIT
            </Button>
          </Box>
        )}
      </Stack>

      <Grid container spacing={4} mt={1}>
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.ProgramId
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.ProgramId
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Program
              </Typography>

              <Controller
                name="program"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    size="small"
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    value={value}
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  required: {
                    value:
                      (programOptions.length > 0 &&
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.ProgramId
                        )?.required) ??
                      false,
                    message: Constant.FIELD_REQUIRED,
                  },
                }}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.ProductId
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.ProductId
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Product
              </Typography>
              <Controller
                name="product"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    size="small"
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    value={value}
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.Dosage
          ) &&
          getFieldValidation(programOptions[0].config_data, FieldNames.Dosage)
            ?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Dosage
              </Typography>
              <Controller
                name="dosage"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    value={value}
                    size="small"
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.DoctorFullName
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.DoctorFullName
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Doctor
              </Typography>
              <Controller
                name="doctor_name"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    inputProps={{ readOnly: true }}
                    value={value}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.Hospital
          ) &&
          getFieldValidation(programOptions[0].config_data, FieldNames.Hospital)
            ?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Hospital/Clinic
              </Typography>
              <Controller
                name="hospital"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    value={value}
                    size="small"
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.IsIndianNational
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.IsIndianNational
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Is an Indian National?
              </Typography>
              <Typography color={"black"} variant="subtitle2">
                {selectedCard.is_indian_national == 1 ? "Yes" : "No"}
              </Typography>
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.Country
          ) &&
          getFieldValidation(programOptions[0].config_data, FieldNames.Country)
            ?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Country
              </Typography>
              <Controller
                name="country"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    inputProps={{ readOnly: true }}
                    value={value?.name}
                    size="small"
                    className={isBlurred ? "blurred-text" : ""}
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.CouponCode
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.CouponCode
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Coupon Code
              </Typography>
              <Controller
                name="couponcode"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    inputProps={{ readOnly: true }}
                    value={value}
                    size="small"
                    className={isBlurred ? "blurred-text" : ""}
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.Mobile
          ) &&
          getFieldValidation(programOptions[0].config_data, FieldNames.Mobile)
            ?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Primary Contact
              </Typography>
              <Controller
                name="primarycontact"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    variant="standard"
                    value={value}
                    size="small"
                    className={isBlurred ? "blurred-text" : ""}
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  pattern: {
                    value: Constant.CORRECT_MOBILE.VALUE,
                    message: Constant.CORRECT_MOBILE.MESSAGE,
                  },
                }}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.AltMobile
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.AltMobile
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Secondary Contact
              </Typography>
              <Controller
                name="secondarycontact"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    value={value}
                    className={isBlurred ? "blurred-text" : ""}
                    inputProps={{ readOnly: true }}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  pattern: {
                    value: Constant.CORRECT_MOBILE.VALUE,
                    message: Constant.CORRECT_MOBILE.MESSAGE,
                  },
                }}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.BaselineIop
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.BaselineIop
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Baseline IOP
              </Typography>
              <Controller
                name="baselineIOP"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    value={value}
                    type="number"
                    size="small"
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(programOptions[0].config_data, FieldNames.IOP) &&
          getFieldValidation(programOptions[0].config_data, FieldNames.IOP)
            ?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Targeted IOP
              </Typography>
              <Controller
                name="iop"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    inputProps={{ readOnly: true }}
                    value={value}
                    size="small"
                    type="number"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.DiseaseDuration
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.DiseaseDuration
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Glaucoma diagnosed years
              </Typography>
              <Controller
                name="numberOfYearsOfGlucoma"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    inputProps={{ readOnly: true }}
                    value={value}
                    type="number"
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.PrescribedDrugs
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.PrescribedDrugs
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Drugs currently prescribed for managing Glaucoma
              </Typography>
              <Controller
                name="currentDrugs"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    inputProps={{ readOnly: true }}
                    value={value}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}

        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.PackScheme
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.PackScheme
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Pack Scheme
              </Typography>
              <Controller
                name="pack_scheme"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    value={value}
                    size="small"
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.ComorbidCondtitions
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.ComorbidCondtitions
          )?.visibility && (
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "8px",
                  color: "#6B6B6B",
                }}
              >
                Comorbid Disease conditions
              </Typography>
              <Controller
                name="comorbidDiseaseconditions"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    size="small"
                    aria-multiline
                    value={value}
                    inputProps={{ readOnly: true }}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TreatmentCycle
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TreatmentCycle
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Treatment Cycle
              </Typography>
              <Controller
                name="treatmentcycle"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    value={value}
                    size="small"
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.Diagnosis
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.Diagnosis
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Diagnosis
              </Typography>
              <Controller
                name="diagnosis"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    value={value}
                    size="small"
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
          )}

        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.NoOfTherapyMonths
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.NoOfTherapyMonths
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Number of Therapy Months
              </Typography>
              <Controller
                name="no_of_therapy_months"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    value={value}
                    size="small"
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
          )}

        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.NoOfFreeMonths
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.NoOfFreeMonths
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Number of Free Months
              </Typography>
              <Controller
                name="no_of_free_months"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    value={value}
                    size="small"
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.PrescriptionDate
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.PrescriptionDate
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Prescription Date
              </Typography>
              <Controller
                name="prescriptiondate"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    value={value}
                    size="small"
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TransplantDate
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TransplantDate
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Transplant Date
              </Typography>
              <Controller
                name="transplantdate"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={value}
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TreatmentStartDate
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TreatmentStartDate
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Treatment Start Date
              </Typography>
              <Controller
                name="treatmentdate"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={value}
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.NextFreeCycleDate
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.NextFreeCycleDate
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Next free cycle Date
              </Typography>
              <Controller
                name="freecycledate"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={value}
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.NextInfusionDate
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.NextInfusionDate
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Next Infusion Date
              </Typography>
              <Controller
                name="next_infusion_date"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    value={value}
                    size="small"
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.LastInfusionDate
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.LastInfusionDate
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Last Infusion Date
              </Typography>
              <Controller
                name="last_infusion_date"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={value}
                    error={error && true}
                    inputProps={{ readOnly: true }}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TotalCyclesCompleted
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TotalCyclesCompleted
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Total Cycles Completed
              </Typography>
              <Controller
                name="total_cycles_completed"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    size="small"
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    value={value}
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TotalCyclesRemaining
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TotalCyclesRemaining
          )?.visibility && (
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "6px",
                  color: "#6B6B6B",
                }}
              >
                Total Cycles Remaining
              </Typography>
              <Controller
                name="total_cycles_remaining"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    size="small"
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    value={value}
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.Comments
          ) &&
          getFieldValidation(programOptions[0].config_data, FieldNames.Comments)
            ?.visibility && (
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "8px",
                  color: "#6B6B6B",
                }}
              >
                Comments
              </Typography>
              <Controller
                name="comments"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    size="small"
                    className={isBlurred ? "blurred-text" : ""}
                    aria-multiline
                    value={value}
                    inputProps={{ readOnly: true }}
                    onChange={onChange}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.MetaData
          ) &&
          getFieldValidation(programOptions[0].config_data, FieldNames.MetaData)
            ?.visibility && (
            <Grid item xs={12}>
              <Stack>
                <Stack
                  flexDirection={"row"}
                  gap={1}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Typography
                      sx={{
                        fontFamily: "Segoe UI",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#6B6B6B",
                      }}
                    >
                      TAC-Test Data
                    </Typography>
                    {!addTacTestData && !isBlurred && (
                      <Tooltip title={"Add Tac Data"}>
                        <IconButton
                          size="small"
                          sx={{ marginBottom: "6px" }}
                          onClick={() => setAddTacTestData(true)}
                        >
                          <AddCircleIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>

                {addTacTestData ? (
                  <AddNewTacTestData handleAddTacData={handleAddTacData} />
                ) : (
                  <TableContainer>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              border: "1px solid lightGrey",
                              backgroundColor: "secondary.main",
                            }}
                          >
                            <Typography
                              fontWeight={660}
                              variant="caption"
                              color={"white"}
                            >
                              Type
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              border: "1px solid lightGrey",
                              backgroundColor: "secondary.main",
                            }}
                          >
                            <Typography
                              fontWeight={660}
                              variant="caption"
                              color={"white"}
                            >
                              Count{" "}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getTacTestData().length > 0 && !isBlurred ? (
                          getTacTestData().map((test: any, index: number) => {
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  align="center"
                                  sx={{ border: "1px solid lightGrey" }}
                                >
                                  <Typography variant="caption">
                                    {toTitleCase(test.type)}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ border: "1px solid lightGrey" }}
                                >
                                  {editedTacData?.type != test.type && (
                                    <Stack
                                      flexDirection={"row"}
                                      gap={1}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                    >
                                      <Typography variant="caption">
                                        {test.value}
                                      </Typography>
                                      <IconButton
                                        onClick={() =>
                                          handleEditTacIndex(
                                            test.type,
                                            test.value
                                          )
                                        }
                                      >
                                        <EditIcon sx={{ fontSize: "12px" }} />
                                      </IconButton>
                                    </Stack>
                                  )}
                                  {editedTacData?.type == test.type &&
                                    !isBlurred && (
                                      <TextField
                                        size="small"
                                        inputProps={{
                                          min: 0,
                                          type: "number",
                                        }}
                                        onWheel={(
                                          e: React.WheelEvent<HTMLInputElement>
                                        ) => {
                                          (e.target as HTMLInputElement).blur();
                                        }}
                                        value={
                                          editedTacData && editedTacData.value
                                            ? editedTacData.value
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleEditTacIndex(
                                            test.type,
                                            parseInt(e.target.value)
                                          )
                                        }
                                        InputProps={{
                                          endAdornment: (
                                            <Button
                                              size="small"
                                              variant="contained"
                                              color="secondary"
                                              onClick={() => updateTacData()}
                                            >
                                              <Typography
                                                textTransform={"none"}
                                                variant="caption"
                                                fontWeight={660}
                                              >
                                                Save
                                              </Typography>{" "}
                                            </Button>
                                          ),
                                        }}
                                      />
                                    )}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <Typography variant="caption" fontWeight={660}>
                            No records found
                          </Typography>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Stack>
            </Grid>
          )}
      </Grid>
    </Box>
  );
};
export default StaticProgram;
