import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    scrollbar: object;
  }
  interface ThemeOptions {
    scrollbar?: object;
  }
}

const theme = createTheme({
  palette: {
    secondary: {
      main: "#3F51B7",
    },
    info: {
      main: "#667085",
      light: "#6B6B6B",
    },
    warning: {
      main: "#FFE9CE",
      dark: "#FF9600",
    },
  },
  scrollbar: {
    overflowY: "auto",
    scrollbarWidth: "thin", // Firefox
    "&::-webkit-scrollbar": {
      width: "6px", // Chrome, Safari, Edge
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555",
    },
  },
});

export default theme;
