enum DomainName {
  ASTELLAS = "astellas.connectod.com",
  OSTEO = "osteo.connectod.com",
  EISAI = "eisai.connectod.com",
  DRISHTI = "drishti.connectod.com",
  DOSTPLUSX = "dostplusx.connectod.com",
  locahost = "localhost",
  SECURITY = "patient-support-osteo-staging-dot-oncology-patient-support.el.r.appspot.com",
}

export default DomainName;
