import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import GaTextArea from "../GaTextArea";
import { useState } from "react";

type Props = {
  openDeleteEnrollmentModal: boolean;
  handleCloseDeleteEnrollmentModel: Function;
  handleSuccessCallback: Function;
  message: string;
  loading: boolean;
};

const style = {
  borderRadius: "12px",
  display: "flex",
  gap: 2,
  flexDirection: "column",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const DeleteEnrollmentModal: React.FC<Props> = ({
  openDeleteEnrollmentModal,
  handleCloseDeleteEnrollmentModel,
  handleSuccessCallback,
  message,
  loading,
}) => {
  const [reason, setReason] = useState<string>("");
  return (
    <Modal
      open={openDeleteEnrollmentModal}
      onClose={() => {
        handleCloseDeleteEnrollmentModel();
        setReason("");
      }}
    >
      <Box sx={style}>
        <Typography>{message}</Typography>
        <GaTextArea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          error={false}
          errorMessage=""
          placeholder="Please mention reason for Deleating Enrollment"
        />
        <Stack
          flexDirection={"row"}
          gap={1}
          alignItems={"center"}
          justifyContent={"end"}
        >
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={reason == ""}
            onClick={() => handleSuccessCallback(reason)}
          >
            <Typography textTransform={"none"}>Confirm</Typography>
          </LoadingButton>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleCloseDeleteEnrollmentModel();
              setReason("");
            }}
          >
            <Typography textTransform={"none"}>Cancel</Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default DeleteEnrollmentModal;
