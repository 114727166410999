import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  CAPTCHA_HEADER,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";
import { RemoteDeleteEnrollment } from "../../../data/usecases/remote-delete-enrollment";
import Swal from "sweetalert2";
import { useEnrollmentMainStore } from "./enrollmentMainStore";

interface deleteEnrollmentSliceInterface {
  deleteEnrollment: Function;
  deleteEnrollmentLoading: FetchState;
}

const initialStates = {
  deleteEnrollmentLoading: FetchState.DEFAULT,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useDeleteEnrollmentSlice: StateCreator<
  deleteEnrollmentSliceInterface
> = (set, get) => ({
  ...initialStates,
  deleteEnrollment: async (id: number, remark: string) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token ?? "" });
    const remoteDeleteEnrollment = new RemoteDeleteEnrollment(
      `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.DELETE_ENROLLMENT}`,
      axiosHttpClient
    );
    set(() => ({ deleteEnrollmentLoading: FetchState.LOADING }));
    let result = await remoteDeleteEnrollment.delete(id, { remark });
    if (result.success) {
      set(() => ({
        deleteEnrollmentLoading: FetchState.SUCCESS,
      }));
      useEnrollmentMainStore
        .getState()
        .fetchEnrollments(1, useEnrollmentMainStore.getState().filter, false);
      Swal.fire(result.message, "", "success");
    } else {
      set(() => ({ deleteEnrollmentLoading: FetchState.ERROR }));
      Swal.fire(result.message, "", "error");
    }
  },
});
