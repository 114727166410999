import { Box, Modal, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { usePersonalDetailStore } from "../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { useProgramDetailStore } from "../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import { useSendToCFAStore } from "../../../store/adminPageStore/patientDetailsStore/sendToCFAStore/sendToCFAStore";
import { EnrollmentDetails } from "../../../domain/models/create-enrollment";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Constant } from "../../../constants/constant";
import moment from "moment";
import { Dayjs } from "dayjs";
import { ProgramInitiator } from "../../../constants/enums/programInitiator";
import { getDomain, getFieldValidation } from "../../../domain/Utils";
import DomainName from "../../../domain/domain";
import { FieldNames } from "../../../constants/enums/fieldNames";
import { ProgramOptionType } from "../../../domain/models/program-option-type";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../../base";
import useRecaptcha from "../../hooks/reCaptcha";
import { FetchState } from "../../../domain/models/fetch-state-type";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  borderRadius: "22px",
  boxShadow: 24,
  p: 4,
};

const AddNewEnrollment: React.FC = () => {
  const [openDate, setOpenDate] = useState<boolean>(false);
  const {
    handleCloseEnrollmentModal,
    addNewEnrollment,
    createEnrollment,
    verifyMobile,
    mobileVerificationStatus,
    verifyMobileState,
  } = useEnrollmentMainStore();

  const { handleSubmit, control, setValue, watch, setError, clearErrors } =
    useForm<EnrollmentDetails>({
      mode: "onChange",
    });
  const { productOptions, fetchProductOptions } = useSendToCFAStore();
  const {
    captchaToken: fetchProductOptionsToken,
    executeRecaptcha: executeFetchProductOptionsRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchProductOptions");

  const [selectedProgram, setSelectedProgram] =
    useState<ProgramOptionType | null>(null);
  const { handlePincode, district, state, country, city, handleRefreshState } =
    usePersonalDetailStore();
  const { programOptions } = useProgramDetailStore();

  const handleCloseModal = () => {
    handleCloseEnrollmentModal();
  };

  const handleClick = (data: EnrollmentDetails) => {
    let payload = {
      initiator: ProgramInitiator.ADMIN,
      patient_caretaker_name: null,
      hospital_coordinator_name: null,
      patient_full_name: data.fullName,
      patient_status: null,
      email: data.email,
      mobile: data.contact,
      gender: data.gender,
      demo_confirmation: data.demoConfirmation ? 1 : 0,
      program_id: data.selectedProgram,
      product_id: data.selectedProduct,
      prescription_uuid: null,
      address_proof_uuid: null,
      identity_uuid: null,
      date_of_birth: data.dateOfBirth
        ? moment((data.dateOfBirth as Dayjs).toString()).format(
            Constant.YEAR_MONTH_DATE_FORMAT
          )
        : null,
      age: data.age,
      address: {
        pin_code: data.address.pincode,
        district: data.address.district,
        state: data.address.state,
        line: data.address.line,
        landmark: data.address.landmark,
        country: data.address.country,
        city: data.address.city,
      },
      doctor_full_name: null,
      hospital_name: null,
      consent_accepted: false,
    };
    if (payload) {
      createEnrollment(payload);
    }
  };

  useEffect(() => {
    setValue("selectedProgram", null);
    setValue("selectedProduct", null);
    setValue("fullName", "");
    setValue("gender", "");
    setValue("contact", "");
    setValue("address.line", "");
    setValue("address.landmark", "");
    setValue("address.pincode", "");
    setValue("dateOfBirth", null);
    setValue("age", null);
    handleRefreshState();
  }, [addNewEnrollment]);

  useEffect(() => {
    if (watch("dateOfBirth")) {
      calculateAge();
    }
  }, [watch("dateOfBirth")]);

  useEffect(() => {
    if (watch("selectedProgram")) {
      executeFetchProductOptionsRecaptcha();
    }
  }, [watch("selectedProgram")]);

  useEffect(() => {
    if (fetchProductOptionsToken) {
      let params = {
        program_id: watch("selectedProgram"),
      };
      fetchProductOptions(fetchProductOptionsToken, params);
    }
  }, [fetchProductOptionsToken]);

  const showDemoConfirmField = () => {
    const showDemoField = programOptions.find(
      (item) => item.id.toString() == watch("selectedProgram")
    );
    if (showDemoField) {
      if (showDemoField.demoable == 1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const calculateAge = () => {
    const today = new Date();
    const birthDate = new Date(
      moment((watch("dateOfBirth") as Dayjs).toString()).format("YYYY-MM-DD")
    );
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    setValue("age", age);
  };

  const filterProgramOptions = () => {
    if (getDomain() == DomainName.ASTELLAS) {
      const program = programOptions.filter((item) => item.id == 2);
      setSelectedProgram(program[0]);
      return program;
    } else if (getDomain() == DomainName.OSTEO) {
      const program = programOptions.filter((item) => item.id == 1);
      setSelectedProgram(program[0]);
      return program;
    } else if (getDomain() == DomainName.EISAI) {
      const program = programOptions.filter((item) => item.id == 3);
      setSelectedProgram(program[0]);
      return program;
    } else if (getDomain() == DomainName.DRISHTI) {
      const program = programOptions.filter((item) => item.id == 4);
      setSelectedProgram(program[0]);
      return program;
    } else if (getDomain() == DomainName.DOSTPLUSX) {
      const program = programOptions.filter((item) => item.id == 5);
      setSelectedProgram(program[0]);
      return program;
    } else if (getDomain() == DomainName.SECURITY) {
      const program = programOptions.filter((item) => item.id == 2);
      setSelectedProgram(program[0]);
      return program;
    } else {
      setSelectedProgram(null);
      return [];
    }
  };

  useEffect(() => {
    setValue("address.city", city);
    setValue("address.country", country);
    setValue("address.district", district);
    setValue("address.state", state);
  }, [city, country, district, state]);

  useEffect(() => {
    clearErrors("contact");
    const isValidContact = Constant.CORRECT_MOBILE.VALUE.test(watch("contact"));

    if (isValidContact && watch("contact").length == 10) {
      verifyMobile(watch("contact"));
    }
  }, [watch("contact")]);

  useEffect(() => {
    if (!mobileVerificationStatus && verifyMobileState === FetchState.SUCCESS) {
      setError("contact", {
        type: "required",
        message: "Mobile number already exists",
      });
    } else {
      clearErrors("contact");
    }
  }, [mobileVerificationStatus, verifyMobileState, setError, clearErrors]);

  return (
    <Modal open={addNewEnrollment} onClose={() => handleCloseEnrollmentModal()}>
      <Box sx={[style]}>
        <Typography variant="h6" fontWeight={600} gutterBottom>
          Enrollment Form
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Controller
              name="selectedProgram"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  select
                  fullWidth
                  label="Select Program"
                  value={value}
                  onChange={onChange}
                  error={error && true}
                  helperText={error?.message}
                >
                  {filterProgramOptions().map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: {
                  value: true,
                  message: "Field Required",
                },
              }}
            />
          </Grid>

          {selectedProgram != null &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.ProductId
            ) &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.ProductId
            )?.visibility && (
              <Grid item xs={4}>
                <Controller
                  name="selectedProduct"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      select
                      fullWidth
                      label="Select Product"
                      value={value}
                      onChange={onChange}
                      error={error && true}
                      helperText={error?.message}
                    >
                      {productOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  rules={{
                    required: {
                      value:
                        (selectedProgram != null &&
                          getFieldValidation(
                            selectedProgram!.config_data,
                            FieldNames.ProductId
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },
                  }}
                />
              </Grid>
            )}
          {selectedProgram != null &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.PatientFullName
            ) &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.PatientFullName
            )?.visibility && (
              <Grid item xs={4}>
                <Controller
                  name="fullName"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      label="Patient Name"
                      value={value}
                      onChange={onChange}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        (selectedProgram != null &&
                          getFieldValidation(
                            selectedProgram!.config_data,
                            FieldNames.PatientFullName
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },
                  }}
                />
              </Grid>
            )}
          {selectedProgram != null &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.Gender
            ) &&
            getFieldValidation(selectedProgram!.config_data, FieldNames.Gender)
              ?.visibility && (
              <Grid item xs={4}>
                <Controller
                  name="gender"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      select
                      label="Patient Gender"
                      value={value}
                      onChange={onChange}
                      error={error && true}
                      helperText={error?.message}
                    >
                      <MenuItem key={"male"} value={"male"}>
                        Male
                      </MenuItem>
                      <MenuItem key={"female"} value={"female"}>
                        Female
                      </MenuItem>
                      <MenuItem key={"other"} value={"others"}>
                        Other
                      </MenuItem>
                    </TextField>
                  )}
                  rules={{
                    required: {
                      value:
                        (selectedProgram != null &&
                          getFieldValidation(
                            selectedProgram!.config_data,
                            FieldNames.Gender
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },
                  }}
                />
              </Grid>
            )}
          {selectedProgram != null &&
            getFieldValidation(selectedProgram!.config_data, FieldNames.DOB) &&
            getFieldValidation(selectedProgram!.config_data, FieldNames.DOB)
              ?.visibility && (
              <Grid item xs={4}>
                <Controller
                  name="dateOfBirth"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          open={openDate}
                          onClose={() => {
                            setOpenDate(false);
                          }}
                          onOpen={() => {
                            setOpenDate(true);
                          }}
                          disableFuture
                          value={value}
                          onChange={onChange}
                          format="DD/MM/YYYY"
                        />
                      </LocalizationProvider>
                    </>
                  )}
                />
              </Grid>
            )}
          {selectedProgram != null &&
            getFieldValidation(selectedProgram!.config_data, FieldNames.Age) &&
            getFieldValidation(selectedProgram!.config_data, FieldNames.Age)
              ?.visibility && (
              <Grid item xs={4}>
                <Controller
                  name="age"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      value={value}
                      placeholder="Age"
                      onChange={(event: any) => {
                        onChange(event.target.value);

                        setValue("dateOfBirth", null);
                      }}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        (selectedProgram != null &&
                          getFieldValidation(
                            selectedProgram!.config_data,
                            FieldNames.Age
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },
                    pattern: {
                      value: Constant.NUMBER_ONLY.VALUE,
                      message: Constant.NUMBER_ONLY.MESSAGE,
                    },
                  }}
                />
              </Grid>
            )}
          {selectedProgram != null &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.Email
            ) &&
            getFieldValidation(selectedProgram!.config_data, FieldNames.Email)
              ?.visibility && (
              <Grid item xs={4}>
                <Controller
                  name="email"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      label="Email *"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    pattern: {
                      value: Constant.CORRECT_EMAIL.VALUE,
                      message: "Invalid email",
                    },
                    required: {
                      value:
                        (selectedProgram != null &&
                          getFieldValidation(
                            selectedProgram!.config_data,
                            FieldNames.Email
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },
                  }}
                />
              </Grid>
            )}
          {selectedProgram != null &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.Mobile
            ) &&
            getFieldValidation(selectedProgram!.config_data, FieldNames.Mobile)
              ?.visibility && (
              <Grid item xs={4}>
                <Controller
                  name="contact"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      label="Mobile Number"
                      value={value}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          onChange(e);
                        }
                      }}
                      error={error && true}
                      helperText={
                        verifyMobileState === FetchState.LOADING
                          ? "Please wait mobile number is verifying..."
                          : error?.message
                      }
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        (selectedProgram != null &&
                          getFieldValidation(
                            selectedProgram!.config_data,
                            FieldNames.Mobile
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },
                    pattern: {
                      value: Constant.CORRECT_MOBILE.VALUE,
                      message: Constant.CORRECT_MOBILE.MESSAGE,
                    },
                    validate: {
                      mobileVerificationCheck: () => {
                        const mobileVerification =
                          useEnrollmentMainStore.getState()
                            .mobileVerificationStatus;
                        if (
                          !mobileVerification &&
                          verifyMobileState === FetchState.SUCCESS
                        ) {
                          return "Mobile number already exists";
                        }
                        return true;
                      },
                    },
                  }}
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight={600}>
              Address:
            </Typography>
          </Grid>
          {selectedProgram != null &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddressLine
            ) &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddressLine
            )?.visibility && (
              <Grid item xs={4}>
                <Controller
                  name="address.line"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      label="Line"
                      value={value}
                      onChange={onChange}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        (selectedProgram != null &&
                          getFieldValidation(
                            selectedProgram!.config_data,
                            FieldNames.AddressLine
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },
                  }}
                />
              </Grid>
            )}
          {selectedProgram != null &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddessLandmark
            ) &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddessLandmark
            )?.visibility && (
              <Grid item xs={4}>
                <Controller
                  name="address.landmark"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      label="Landmark"
                      value={value}
                      onChange={onChange}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        (selectedProgram != null &&
                          getFieldValidation(
                            selectedProgram!.config_data,
                            FieldNames.AddessLandmark
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },
                  }}
                />
              </Grid>
            )}
          {selectedProgram != null &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddressPincode
            ) &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddressPincode
            )?.visibility && (
              <Grid item xs={4}>
                <Controller
                  name="address.pincode"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      label="Pincode"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);

                        handlePincode(e.target.value);
                      }}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        (selectedProgram != null &&
                          getFieldValidation(
                            selectedProgram!.config_data,
                            FieldNames.AddressPincode
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },

                    pattern: {
                      value: /^[1-9]{1}\d{2}\s?\d{3}$/,
                      message: "Enter 6 digits pincode",
                    },
                  }}
                />
              </Grid>
            )}
          {selectedProgram != null &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddressCity
            ) &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddressCity
            )?.visibility && (
              <Grid item xs={3}>
                <Controller
                  name="address.city"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      disabled
                      fullWidth
                      label="City"
                      value={value}
                      InputProps={{
                        readOnly: true,
                      }}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        (selectedProgram != null &&
                          getFieldValidation(
                            selectedProgram!.config_data,
                            FieldNames.AddressCity
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },
                  }}
                />
              </Grid>
            )}
          {selectedProgram != null &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddressDistrict
            ) &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddressDistrict
            )?.visibility && (
              <Grid item xs={3}>
                <Controller
                  name="address.district"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      disabled
                      fullWidth
                      label="District"
                      value={value}
                      InputProps={{
                        readOnly: true,
                      }}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        (selectedProgram != null &&
                          getFieldValidation(
                            selectedProgram!.config_data,
                            FieldNames.AddressDistrict
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },
                  }}
                />
              </Grid>
            )}
          {selectedProgram != null &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddressState
            ) &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddressState
            )?.visibility && (
              <Grid item xs={3}>
                <Controller
                  name="address.state"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      disabled
                      fullWidth
                      label="State"
                      value={value}
                      InputProps={{
                        readOnly: true,
                      }}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        (selectedProgram != null &&
                          getFieldValidation(
                            selectedProgram!.config_data,
                            FieldNames.AddressState
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },
                  }}
                />
              </Grid>
            )}
          {selectedProgram != null &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddressCountry
            ) &&
            getFieldValidation(
              selectedProgram!.config_data,
              FieldNames.AddressCountry
            )?.visibility && (
              <Grid item xs={3}>
                <Controller
                  name="address.country"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      disabled
                      fullWidth
                      label="Country"
                      value={value}
                      InputProps={{
                        readOnly: true,
                      }}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        (selectedProgram != null &&
                          getFieldValidation(
                            selectedProgram!.config_data,
                            FieldNames.AddressCountry
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },
                  }}
                />
              </Grid>
            )}
        </Grid>
        {selectedProgram != null &&
          getFieldValidation(
            selectedProgram!.config_data,
            FieldNames.DemoConfirmation
          ) &&
          selectedProgram != null &&
          getFieldValidation(
            selectedProgram!.config_data,
            FieldNames.DemoConfirmation
          )?.visibility &&
          showDemoConfirmField() && (
            <FormGroup>
              <Controller
                name="demoConfirmation"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Demo Confirmation"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </FormGroup>
          )}
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          marginTop={1}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleClick)}
          >
            Submit
          </Button>
          <Button variant="outlined" color="error" onClick={handleCloseModal}>
            Close
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddNewEnrollment;
